import { createPinia, type Pinia } from "pinia";

/** Pinia Store */
const pinia: Pinia = createPinia();
//pinia.use(piniaPluginPersistedstate);

export default pinia;

// Pinia Stores

import useConfig from "./ConfigStore";
import useGlobal from "./GlobalStore";

export { useConfig, useGlobal };
