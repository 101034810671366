{
	"dashboard": {
		"created": "Created",
		"deleted": "Deleted",
		"edited": "Edited",
		"entriesoflast7days": "Synced entries per day from the last 7 days",
		"failedruns": "Failed runs",
		"noactiveplugin": "There is no configuration marked as active for this plugin",
		"noplugins": "You don't have a plugin with a configuration marked as active",
		"oflastmonth": "last 30 days",
		"oflastweek": "last 7 days",
		"pluginoverview": "Your plugin overview",
		"runs": "Runs",
		"runstotal": "Runs total",
		"successfullruns": "Successfull runs",
		"welcome": "Welcome",
		"witherrorruns": "with errors",
		"withwarningruns": "with warnings"
	},
	"jobConfigs": {
		"active": "active",
		"button": {
			"edit": "Edit Config",
			"start": "Start immediately",
			"startcomplete": "Start immediately and sync all content"
		},
		"lastrun": "last run",
		"nextrun": "next run",
		"runstotal": "total runs"
	},
	"joboverview": {
		"table": {
			"details": "details",
			"end": "end",
			"endTill": "end till",
			"error": "failed",
			"filter": "filter",
			"finished": "done",
			"name": "name",
			"plugin": "plugin",
			"resultType": "status",
			"start": "start",
			"startFrom": "start from",
			"started": "started",
			"state": "state"
		}
	},
	"navigation": {
		"dashboard": "Dashboard",
		"jobconfigs": "Plugin Configs",
		"joboverview": "Logs",
		"pluginsoverview": "Plugin Overview"
	},
	"pagination": {
		"first": "first",
		"itemsPerPage": "items per page",
		"last": "last",
		"next": "next",
		"page": "page { actualPage } of { totalPages }",
		"prev": "previous"
	},
	"schoolConnexTask": {
		"results": {
			"contains_error": "This task failed",
			"contains_warning": "This task contains a warning",
			"errors": "Number of errors",
			"headers": {
				"id": "Id",
				"id_moin_schule": "Id Moin.Schule",
				"identifier": "Identifier",
				"internal_id": "Internal Id",
				"message": "Message",
				"operation": "Operation",
				"status": "Status",
				"type": "Type"
			},
			"is_deprecated": "This task is deprecated",
			"is_successfull": "This task was successfull",
			"is_unknown": "Unknown",
			"log_messages": "Log Messages",
			"mdmId": "Id mdm",
			"message": "Message",
			"operation": "Operation",
			"statistic": "Statistic",
			"total": "Total Elements",
			"type": "Type",
			"untisId": "Id Untis",
			"warnings": "Total Warnings"
		}
	},
	"untisMdm": {
		"config": {},
		"results": {
			"data": "Data",
			"errors": "errors",
			"id": "Id",
			"identifier": "Identifier",
			"log_messages": "Log Messages",
			"mdm_classname": "Mdm classname",
			"mdmId": "Id mdm",
			"message": "message",
			"operation": "operation",
			"statistic": "Statistics",
			"status": "status",
			"taskId": "taskId",
			"total": "total numbers",
			"type": "type",
			"untis_classname": "Untis classname",
			"untisId": "Id Untis",
			"warnings": "warnings"
		}
	}
}
