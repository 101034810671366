<template>
	<v-app>
		<drawer-component display-items />
		<v-main>
			<v-container class="h-screen pa-0" fluid>
				<component :is="authView"></component>
			</v-container>
		</v-main>
	</v-app>
</template>

<script setup lang="ts">
	import {
		AccessRequirements,
		checkRequirementsInjectionKey,
		contentHeaderPropsInjectionKey,
		usePreferredLanguage,
	} from "@de/ist.style_components_v3";
	import { useAuth } from "@de/ist-cloud-auth";
	import { useAuthView } from "@de/ist-cloud-auth";
	import { computed, provide } from "vue";

	import DrawerComponent from "./components/DrawerComponent.vue";
	import { localeInjectionKey } from "./shared/injectionKeys";

	const locale = usePreferredLanguage(["en", "de"]);
	provide(localeInjectionKey, locale);

	const authView = useAuthView();

	const { firstName, lastName, organisationName, roleName, canAccess } =
		useAuth();

	const orgaTitle = computed(
		() => `${organisationName.value} - ${roleName.value}`
	);

	provide(contentHeaderPropsInjectionKey, () => ({
		firstName,
		lastName,
		orgaTitle,
	}));
	provide(
		checkRequirementsInjectionKey,
		(requirements?: AccessRequirements) => {
			return canAccess(requirements);
		}
	);
</script>

<style>
	html,
	body,
	.v-application,
	.v-application--wrap,
	.v-application__wrap,
	.v-main,
	.v-main__wrap,
	.v-container,
	.height-100,
	#app {
		height: 100%;
	}

	.v-application {
		font-family: "Libre Franklin", sans-serif;
	}

	html {
		overflow-y: hidden !important;
	}
</style>
