{
	"dashboard": {
		"created": "Neu angelegt",
		"deleted": "Gelöscht",
		"edited": "Editiert",
		"entriesoflast7days": "Übertragene Datensätze pro Tag der letzten 7 Tage",
		"failedruns": "Fehlgeschlagene Läufe",
		"noactiveplugin": "In diesem Plugin ist keine Konfiguration als aktiv markiert",
		"noplugins": "Sie haben keine Plugins mit aktiver Konfiguration",
		"oflastmonth": "letzte 30 Tage",
		"oflastweek": "letzte 7 Tage",
		"pluginoverview": "Eine Übersicht Ihrer Plugins",
		"runs": "Durchläufe",
		"runstotal": "Läufe gesamt",
		"successfullruns": "Erfolgreiche Läufe",
		"welcome": "Willkommen",
		"witherrorruns": "davon mit Fehlern",
		"withwarningruns": "davon mit Warnungen"
	},
	"jobConfigs": {
		"active": "active",
		"button": {
			"edit": "Einstellung editieren",
			"start": "sofort Ausführen",
			"startcomplete": "sofort Ausführen und alle Inhalte synchronisieren"
		},
		"lastrun": "letzter Lauf",
		"nextrun": "nächster Lauf",
		"runstotal": "Anzahl Läufe"
	},
	"joboverview": {
		"table": {
			"details": "Details",
			"end": "Ende",
			"endTill": "Ende bis",
			"error": "Fehlgeschlagen",
			"filter": "Filter",
			"finished": "Fertig",
			"name": "Name",
			"plugin": "Plugin",
			"resultType": "Status",
			"start": "Start",
			"startFrom": "Start ab",
			"started": "Gestartet",
			"state": "Status"
		}
	},
	"navigation": {
		"dashboard": "Dashboard",
		"jobconfigs": "Plugin Konfigurationen",
		"joboverview": "Logs",
		"pluginsoverview": "Plugin Übersicht"
	},
	"pagination": {
		"first": "erste",
		"itemsPerPage": "Einträge pro Seite",
		"last": "letzte",
		"next": "nächste",
		"page": "Seite { actualPage } von { totalPages }",
		"prev": "vorherige"
	},
	"schoolConnexTask": {
		"results": {
			"contains_error": "Dieser Task ist fehlgeschlagen",
			"contains_warning": "Dieser Task enthält eine Warnung",
			"errors": "Anzahl Fehler",
			"headers": {
				"id": "Id",
				"id_moin_schule": "Id Moin.Schule",
				"identifier": "Identifier",
				"internal_id": "Id intern",
				"message": "Bemerkung",
				"operation": "Operation",
				"status": "Status",
				"type": "Typ"
			},
			"is_deprecated": "Dieser Task ist veraltet",
			"is_successfull": "Dieser Task war erfolgreich",
			"is_unknown": "Unbekannt",
			"log_messages": "Log Nachrichten",
			"mdmId": "Id mdm",
			"message": "Nachricht",
			"operation": "Operation",
			"statistic": "Statistik",
			"total": "Anzahl Elemente",
			"type": "Typ",
			"untisId": "Id Untis",
			"warnings": "Anzahl Warnungen"
		}
	},
	"untisMdm": {
		"config": {},
		"results": {
			"data": "Daten",
			"errors": "Anzahl Fehler",
			"id": "Id",
			"identifier": "Identifier",
			"log_messages": "Log Nachrichten",
			"mdm_classname": "MDM Klassenname",
			"mdmId": "Id mdm",
			"message": "Nachricht",
			"operation": "Operation",
			"statistic": "Statistik",
			"status": "Status",
			"taskId": "taskId",
			"total": "Anzahl Elemente",
			"type": "Typ",
			"untis_classname": "Untis Klassenname",
			"untisId": "Id Untis",
			"warnings": "Anzahl Warnungen"
		}
	}
}
