import { createI18n } from "vue-i18n";

import translations, { Language } from "@/shared/translations";

const savedLocal = localStorage.getItem("lang") || "de";

export default createI18n<typeof translations, Language, false>({
	fallbackLocale: "de",
	inheritLocale: true,
	legacy: false,
	locale: savedLocal,
	messages: translations,
} as const);
