import { messages } from "@de/ist.style_components_v3";
import { en as vuetifyen } from "vuetify/locale";

import en from "@/locales/en.json";

export default {
	$vuetify: { ...vuetifyen },
	...messages.en,
	...en,
};
