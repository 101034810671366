import {
	createRouter,
	createWebHistory,
	type Router,
	type RouteRecordRaw,
} from "vue-router";

import { apiUrl } from "@/config";

/** Router Rules */
const routes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "default",
		redirect: { name: "DashBoard" },
	},
	{
		path: "/dashboard",
		name: "DashBoard",
		component: async () => import("@/views/DashBoard.vue"),
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/jobconfig",
		name: "JobConfig",
		component: async () => import("@/views/JobConfig.vue"),
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/plugins",
		name: "Plugins",
		component: async () => import("@/views/PluginsOverview.vue"),
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/joboverview",
		name: "JobOverview",
		component: async () => import("@/views/JobOverview.vue"),
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/plugins/detail",
		name: "PluginDetail",
		component: async () => import("@/components/PluginDetail.vue"),
		meta: {
			requireAuth: true,
		},
	},
	{
		component: async () =>
			import("@/connect_plugins/UntisMdm/UntisMdmConfig.vue"),
		meta: {
			requireAuth: true,
		},
		name: "MdmUntis",
		path: "/plugins/untismdm/:id",
		props: true,
	},
	{
		component: async () =>
			import("@/connect_plugins/UntisMdm/UntisMdmTaskDetail.vue"),
		meta: {
			requireAuth: true,
		},
		name: "UntisMdmTaskDetail",
		path: "/tasks/untismdm/:taskid",
		props: true,
	},
	{
		component: async () =>
			import(
				"@/connect_plugins/UntisXschoolSchedule/UntisXschoolScheduleConfig.vue"
			),
		meta: {
			requireAuth: true,
		},
		name: "UntisXschoolSchedule",
		path: "/plugins/untis-xschool-schedule/:id",
		props: true,
	},
	{
		component: async () =>
			import(
				"@/connect_plugins/UntisXschoolSubstitution/UntisXschoolSubstitutionConfig.vue"
			),
		meta: {
			requireAuth: true,
		},
		name: "UntisXschoolSubstitution",
		path: "/plugins/untis-xschool-substitution/:id",
		props: true,
	},
	{
		component: async () =>
			import("@/connect_plugins/SchulConnex/SchulConnexConfig.vue"),
		meta: {
			requireAuth: true,
		},
		name: "SchulConnex",
		path: "/plugins/schulconnex/:id",
		props: true,
	},
	{
		component: async () =>
			import("@/connect_plugins/SchulConnex/SchulConnexTaskDetail.vue"),
		meta: {
			requireAuth: true,
		},
		name: "SchulConnexTaskDetail",
		path: "/tasks/schulconnex/:taskid",
		props: true,
	},
];
/** Vue Router */
const router: Router = createRouter({
	/**
	 * History Mode
	 *
	 * @see {@link https://router.vuejs.org/guide/essentials/history-mode.html}
	 */
	history: createWebHistory(apiUrl),
	routes,
});

export default router;
