/**
 * Vue3 Main script
 */

// Load vue core
import "vue3-toastify/dist/index.css";
import "@de/ist.style_components_v3/dist/ist.style_components_v3.css";

import {
	createStyleComponents,
	versionInjectionKey,
} from "@de/ist.style_components_v3";
import { AuthOptions, createAuth } from "@de/ist-cloud-auth";
import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";

import i18n from "@/plugins/i18n";

// Load Layout vue.
import App from "./App.vue";
// Load Vuetify
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import store from "./store";
import ContentHeaderMenu from "./views/Layout/ContentHeaderMenu.vue";

const options: AuthOptions = {
	rightToUse: "IST.Connect",
};

const auth = createAuth(options);

const styleComponents = createStyleComponents();
const version = import.meta.env.VITE_APP_VERSION;

const vue = createApp(App);
vue.use(router);
vue.use(i18n);
vue.use(store);
vue.use(vuetify);
vue.use(VueApexCharts);
vue.use(auth);
vue.use(styleComponents);
vue.provide(versionInjectionKey, version);
vue.component("ContentHeaderMenu", ContentHeaderMenu);
vue.use(Vue3Toastify, {
	autoClose: 3000,
} as ToastContainerOptions);

// Run!
router.isReady().then(() => {
	vue.mount("#app");
});
