<script setup lang="ts">
	import { VersionNumber } from "@de/ist.style_components_v3";
	import { useCycleList, useToggle, whenever } from "@vueuse/core";
	import { computed, ref } from "vue";
	import { useI18n } from "vue-i18n";

	import type DrawerMenuItem from "@/interfaces/DrawerMenuItemInterface";

	import MenuItem from "./MenuItem.vue";

	const { locale } = useI18n({ useScope: "global" });

	const items = ref<DrawerMenuItem[]>([
		{
			title: "navigation.dashboard",
			icon: "mdi-home",
			to: { name: "DashBoard" },
		},
		{
			title: "navigation.joboverview",
			icon: "mdi-table",
			to: { name: "JobOverview" },
		},
		{
			title: "navigation.jobconfigs",
			icon: "mdi-cog",
			to: { name: "JobConfig" },
		},
		{
			title: "navigation.pluginsoverview",
			icon: "mdi-folder",
			to: { name: "Plugins" },
		},
	]);

	const changeLanguage = (language: string) => {
		locale.value = language;
		localStorage.setItem("lang", language);
	};

	const [rail, toggleRail] = useToggle(false);
	const { state, next } = useCycleList(["de", "en"], {
		initialValue: locale.value as string,
	});
	whenever(state, changeLanguage);
	const flagClass = computed(() => {
		if (state.value === "en") {
			return "fi-us";
		}
		return `fi-${state.value}`;
	});

	defineProps<{
		displayItems: boolean;
	}>();
</script>

<template>
	<v-navigation-drawer color="primary" permanent :rail="rail">
		<template #prepend>
			<div class="drawer-header d-flex align-center pa-2">
				<div class="text-h6 text-truncate">IST.Connect</div>
				<v-btn
					:block="rail"
					class="ml-auto"
					density="comfortable"
					:icon="rail ? 'mdi-menu' : 'mdi-menu-open'"
					:ripple="false"
					rounded="0"
					variant="text"
					@click="toggleRail()"
				></v-btn>
			</div>
		</template>

		<v-list v-if="displayItems" nav>
			<template v-for="item in items" :key="item.title">
				<v-divider v-if="item.title === '-'" />
				<template v-else>
					<!-- Menu Item -->
					<menu-item v-if="!item.items" :item="item"></menu-item>
					<v-list-group v-else-if="item.items" v-model="item.active">
						{{ item.title }}
						<!-- Sub menu item -->
						<template v-for="subItem in item.items" :key="subItem.title">
							<v-divider v-if="subItem.title === '-'" />
							<v-list-item
								v-else
								:disabled="!subItem.to"
								link
								:prepend-icon="subItem.icon"
								:title="subItem.title"
								:to="subItem.to"
							/>
						</template>
					</v-list-group>
				</template>
			</template>
		</v-list>

		<template #append>
			<div
				class="drawer-footer d-flex flex-column justify-center align-center pa-2"
			>
				<v-btn :block="rail" variant="text" @click="next()">
					<span class="fi" :class="flagClass"></span>
				</v-btn>
				<img class="ist-logo pl-1 pr-1" height="56" src="../assets/logo2.svg" />
				<version-number />
			</div>
		</template>
	</v-navigation-drawer>
</template>

<style scoped lang="less">
	.drawer-header {
		height: 62px;
	}

	.drawer-footer {
		gap: 8px;
	}
</style>
